import React from "react";
import '../components/css/engworks.css';
import engworks from '../components/images/engworks.jpg';

function Engworks() {
  return (
    <div className="hello-container">
      <h1 className="hello-typing-text">
      Работаем над улучшениями. Сайт временно недоступен 
      </h1>
      <img src={engworks} alt="iengworks" className="center-image" />
      <div className="copyright"><p>© Larkov.org {new Date().getFullYear()}</p></div>
    </div>
  );
}

export default Engworks;