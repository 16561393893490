import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import DeviceStore from './store/DeviceStore';
import UserStore from './store/UserStore';


export const Context = createContext(null)
console.log(process.env.REACT_APP_API_URL)


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Context.Provider value={{
    user: new UserStore(),
    device: new DeviceStore(),
  }}>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </Context.Provider>
);
//import { createRoot } from 'react-dom/client';

//ReactDOM.render(
  //<Context.Provider value={{}}>
    //  <App />
 // </Context.Provider>,
//document.getElementById('root')
//);




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
