import React from "react";
import Spinner from "react-bootstrap/Spinner";
import "./css/spinner.css";

const MySpinner = ({color}) => {
  return (
    <div className="spinner">
      <Spinner animation="grow" variant={color} />
    </div>
  );
};

export default MySpinner;