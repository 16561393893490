import React, { useContext } from "react";
import { Context } from "../index";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { ADMIN_ROUTE, LOGIN_ROUTE, SHOP_ROUTE, BASKET_ROUTE, HOME_ROUTE } from "../utils/consts";
import {NavLink} from "react-router-dom";
import {Button} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import {useNavigate} from 'react-router-dom';
import './css/navbar.css';
import {Image} from "react-bootstrap";
import basket from '../components/images/basket.png';
import catalog from '../components/images/catalog.png';
import home from '../components/images/home.png';
import userlogo from '../components/images/userlogo.png';
import '../components/css/basket.css';


const NavBar = observer(() => {
    const {user} = useContext(Context)
    const navigate = useNavigate()
   

    const logOut = () => {
        user.setUser({})
        user.setIsAuth(false)
    }

    return (
        <Navbar  className="custom-navbar" >
        <Container>
            <NavLink to={HOME_ROUTE}><Image className="home-img" src={home}></Image></NavLink>
            <NavLink to={SHOP_ROUTE}><Image className="catalog" src={catalog}></Image></NavLink> 
            
            <Image className="basket_nav" src={basket} onClick={() => navigate(BASKET_ROUTE)} />
            

            
          {user.isAuth ?
              <Nav className="ml-auto" style={{color: 'white'}}>
                  <NavLink to={SHOP_ROUTE}><Image className="user-logo" src={userlogo}></Image></NavLink> 

                  <Button variant={"outline-light"}
                   onClick={() => logOut()}
                   style={{color:'orange'}}>Выйти</Button>
              </Nav>
              :
              <Nav className="ml-auto" style={{color: 'white'}}>
                  <Button className="button-nav" variant={"outline-light"}
                   
                   onClick={() => navigate(LOGIN_ROUTE)}>Авторизация</Button> 
              </Nav>
            }              
        </Container>
        </Navbar>
 
    );
});

export default NavBar;

//user.setIsAuth(true)}//

//<Button variant={"outline-light"}
 //                  onClick={() => navigate(ADMIN_ROUTE)}
   //                style={{color:'orange'}}>Админ панель</Button>//